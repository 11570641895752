/* global angular */

'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/dashboard', {
        templateUrl: 'app/dashboard/dashboard.html',
        controller: 'DashboardController',
        hasPermissions: ['DASHBOARD'],
        pageTitle: 'Tableau de bord'
    });
});
